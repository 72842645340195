<template>
  <div class="content d-flex flex-column flex-column-fluid" id="kt_content">
    <Breadcrumb :datas='breadcrumb_data' />
    <!--begin::Entry-->
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t('c_management.level_update_subtitle') }}
              </h3>
            </div>
          </div>
          <div class="card-body">
            <Datatable
                ref="datatable"
                :url="datatableUrl"
                :dataUrl="datatableDataUrl"
                @data="loadDatatableData($event)"
            >
                <!-- <template v-slot:head>
                    Table head özelleştirme
                </template> -->
                <template v-slot:body>
                  <tr v-for="(item, index) in datatableData" :key="'data-' + index">
                    <th scope="row">{{ item.id }}</th>
                    <td>
                      <router-link
                        :to="`/users/${item.user_id}/overview`"
                        class="text-primary font-weight-bold"
                      >
                        {{ item.user }}
                      </router-link>
                    </td>
                    <td>{{ (item.type) ? $t(`service.${item.type}`) : item.type }}</td>
                    <td>{{ (item.status) ? $t(`service.${item.status}`) : item.status }}</td>
                    <td>{{ item.created_at }}</td>
                    <td>{{ item.completed_at }}</td>
                    <td>
                      <div class="d-flex justify-content-end flex-shrink-0">
                        <button @click="showDetailsModal(item.id)" class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1" :title="$t('commons.details')">
                          <i class="flaticon2-document"></i>
                        </button>
                        <button @click="showPastTransactionsModal(item.id)" class="btn btn-icon btn-clean btn-bg-light btn-sm mr-1" :title="$t('employees.past_transactions')">
                          <i class="la la-history"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </template>
            </Datatable>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
    <!--end::Entry-->
    <AuditModal v-if="auditModalVisible" ref="auditModal" :close="() => $store.commit('customerManagement/TOGGLE_LEVEL_UP_AUDIT_MODAL', false)">
      <template v-slot:header>
        <h1>{{ $t('employees.audit_modal_title') }}</h1>
      </template>
      <template v-slot:body>
        <table class="table table-bordered collapsed table-head-bg" style="width:100%">
          <thead>
            <tr>
              <th scope="col">{{ $t('employees.audit_user') }}</th>
              <th scope="col">{{ $t('employees.audit_area') }}</th>
              <th scope="col">{{ $t('employees.audit_old_value') }}</th>
              <th scope="col">{{ $t('employees.audit_new_value') }}</th>
              <th scope="col">{{ $t('employees.audit_date') }}</th>
            </tr>
          </thead>
          <div v-if="audits.length === 0" class="text-center p-5">
            {{ $t('commons.no_data_available') }}
          </div>
          <tbody v-for="(auditItem, index) in audits" :key="'audit-' + index">
            <tr v-for="(newValKey, newValKeyIndex) in Object.keys(auditItem.new_values)" :key="'newValKeyIndex-' + newValKeyIndex">
              <td scope="row"><span class="truncateText">
                <template v-if="auditItem.user">
                  <a href="javascript:;" @click="goToUserDetails(auditItem.user.id)">
                    {{ auditItem.user['name'] }} {{ auditItem.user['surname'] }}
                  </a>
                </template>
                </span>
              </td>
              <td scope="row"><span class="truncateText">{{ newValKey }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.old_values[newValKey] || '' }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.new_values[newValKey] || '' }}</span></td>
              <td scope="row"><span class="truncateText">{{ auditItem.updated_at }}</span></td>
            </tr>
          </tbody>
        </table>
      </template>
    </AuditModal>
    <DetailsModal />
    <SmsModal @levelUpdateSmsConfirmed="initDatatable" />
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb.vue'
import AuditModal from '@/components/custom/AuditModal.vue';
import DetailsModal from './LevelUpdateDetailsModal.vue';
import SmsModal from './LevelUpdateSmsModal.vue';
import Datatable from "@/components/datatable/Datatable.vue";
import api_old from "@/api/index_old";

export default {
  name: 'LevelUpdate',
  data() {
    return {
      isLoading:true,
      datatableUrl: "",
      datatableDataUrl: "",
      datatableData: [],
    }
  },
  components: {
    Breadcrumb,
    AuditModal,
    DetailsModal,
    SmsModal,
    Datatable
  },
  computed: {
    ...mapState({
      level_update_data: state => state.customerManagement.level_update_data,
      auditModalVisible: state => state.customerManagement.level_update_audit_visible,
      audits: state => state.customerManagement.level_update_audit_data,
      permissions: (state) => state.customer.permissions,
      lang: (state) => state.global.lang
    }),
    breadcrumb_data() {
      return {
        title: this.$t('c_management.title'),
        subtitle: [
          this.$t('c_management.level_update')
        ]
      }
    }
  },
  methods: {
    checkPermission(permission) {
      return this.permissions?.some(val => val.name == permission);
    },
    showDetailsModal(id) {
      this.$store.dispatch('customerManagement/GET_LEVEL_UP_DETAILS_MODAL', id);
    },
    showPastTransactionsModal(id) {
      this.$store.dispatch('customerManagement/GET_LEVEL_UP_AUDIT', id);
    },
    goToUserDetails(user_id) {
      this.$store.commit('customerManagement/TOGGLE_LEVEL_UP_AUDIT_MODAL', false)
      this.$router.push({ path: `/users/${user_id}` })
    },
    loadDatatableData(data) {
      this.$set(this, "datatableData", data);
    },
    initDatatable() {
      this.$refs.datatable.loadData();
    }
  },
  created() {
    if(this.permissions?.length > 0 && !this.checkPermission('crm.level-request.index')) {
      this.$router.push('/no-permission')
    }
    this.datatableUrl = api_old.level_update_datatable;
    this.datatableDataUrl = api_old.level_update_datatable_data;
  },
}
</script>
<style lang="scss">
</style>
